@charset "UTF-8";

/* BASE SETTINGS */
body {font: 13px/1.6 "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Ghothic Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "メイリオ", "Meiryo", "ＭＳ Ｐゴシック", "MS PGothic", "MS UI Gothic", "Osaka", Sans-Serif;	color: #000; background-color: transparent;}
h1,h2,h3,h4,h5,h6 {font-size: 100%; font-weight: normal;}

a {
	text-decoration: none;
	&:hover,
	&:visited {
		text-decoration: none;
		outline: none;
	}
	&:focus {
		outline: none;
	}
}

a.tdu {
	text-decoration: underline;
}

.cf {
	*zoom: 1;
	&:before,
	&:after {
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}

a:hover {
	img {
		opacity: 0.8;
		filter: alpha(opacity=80);
	}
}

.container {
	width: 1000px;
	margin: 0 auto;
}
strong {
	font-weight: bold;
}

/* UTILITIES */
.attention {color: #c00;}
.asterisk {color: #c00;}
.note {color: #c00;}

.mb0 {margin-bottom: 0;} .mb5 {margin-bottom: 5px;} .mb10 {margin-bottom: 10px;} .mb15 {margin-bottom: 15px;} .mb20 {margin-bottom: 20px;} .mb25 {margin-bottom: 25px;} .mb30 {margin-bottom: 30px;} .mb35 {margin-bottom: 35px;} .mb40 {margin-bottom: 40px;} .mb45 {margin-bottom: 45px;} .mb50 {margin-bottom: 50px;} .mb55 {margin-bottom: 55px;} .mb60 {margin-bottom: 60px;} .mb65 {margin-bottom: 65px;} .mb70 {margin-bottom: 70px;} .mb80 {margin-bottom: 80px;} .mb90 {margin-bottom: 90px;} .mb95 {margin-bottom: 95px;} .mb100 {margin-bottom: 100px;} .mb120 {margin-bottom: 120px;} .mb180 {margin-bottom: 180px;} .mb1em {margin-bottom: 1em;} .mb15em {margin-bottom: 1.5em;} .mb2em {margin-bottom: 2em;} .mr10 {margin-right: 10px;} .mr20 {margin-right: 20px;} .pb20 {padding-bottom: 20px;} .pb35 {padding-bottom: 35px;} .pb40 {padding-bottom: 40px;} .pb45 {padding-bottom: 45px;} .pb50 {padding-bottom: 50px;} .pb60 {padding-bottom: 60px;}


/* STRUCTURE SETTINGS
------------------------------ */
html, body {
	width: 100%;
}

body {
	position: relative;
	min-width: 1000px;
}

html {
	// background: url(../img/home/bg_bodyTop107.png) 0 0 repeat-x;
}

body#home {

}

.bg-wrapper {
	width: 100%;
	position: relative;
	min-height: 107px;
	height: auto !important;
	height: 107px;
	z-index: 10000; // .slide is 1st layer exc. body/html layer
	background: url(../img/home/bg_bodyTop107.png) 0 0 repeat-x;
}

.mi-wrapper {
	width: 100%;
	min-height: 420px;
	height: auto !important;
	height: 420px;
	position: relative;
	z-index: 0; // .slide is 1st layer exc. body/html layer
}

.pageTitle {
	height: 313px;
	h2 {
		color: #fff;
		font-size: 20px;
		text-indent: -9999px;
	}
}

/* PAGE TITLE ORIGINAL SETTING
COPY & PASE EACH CATEGORY PAGE
------------------------------ */
body.company {
	.mi-wrapper {
		background: url(../img/company/mainImage_eng01.jpg) top center no-repeat;
	}
}

/* GLOBAL NAVI
------------------------------ */
.container.header {
	width: 1000px;
	margin: 0 auto;
	height: 107px;
	position: relative;
	background: url(../img/common/globalNavi_home2.png) 0 bottom no-repeat;
	z-index: 15000;
	h1.site-id {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 240px;
		height: 107px;
		line-height: 107px;
		// border: 1px solid #fff;
		a {
			display: block;
			text-indent: -9999px;
			cursor: pointer;
		}
	}
	ul.globalNavi {
		position: absolute;
		width: 755px;
		top: 0;
		left: 245px;
		li {
			float: left;
			display: inline;
			zoom: 1;
			position: absolute;
			height: 52px;
			line-height: 52px;
			a {
				display: block;
				text-indent: -9999px;
				cursor: pointer;
			}
		}
	}
}

#gn1 { width: 194px; left: 0; }
#gn2 { width: 224px; left: 194px; }
#gn3 { width: 257px; left: 418px; }
#gn4 { width: 80px; left: 675px; }
#gn5 { top: 52px; width: 280px; left: 0; }
#gn6 { top: 52px; width: 130px; left: 280px; }
#gn7 { top: 52px; width: 77px; left: 410px; }
#gn8 { top: 52px; width: 142px; left: 487px; }
#gn9 { top: 52px; width: 126px; left: 629px; }

/* For Changing Hover Image
------------------------------ */
#gn1 a:hover { width: 194px; left: 0; background: url(../img/common/globalNavi_home2y.png) -245px -28px no-repeat;}
#gn2 a:hover { width: 224px; left: 194px; background: url(../img/common/globalNavi_home2y.png) -439px -28px no-repeat;}
#gn3 a:hover { width: 257px; left: 418px; background: url(../img/common/globalNavi_home2y.png) -663px -28px no-repeat;}
#gn4 a:hover { width: 80px; left: 675px; background: url(../img/common/globalNavi_home2y.png) -920px -28px no-repeat;}
#gn5 a:hover { top: 52px; width: 280px; left: 0; background: url(../img/common/globalNavi_home2y.png) -245px -80px no-repeat;}
#gn6 a:hover { top: 52px; width: 130px; left: 280px; background: url(../img/common/globalNavi_home2y.png) -525px -80px no-repeat;}
#gn7 a:hover { top: 52px; width: 77px; left: 410px; background: url(../img/common/globalNavi_home2y.png) -655px -80px no-repeat;}
#gn8 a:hover { top: 52px; width: 142px; left: 487px; background: url(../img/common/globalNavi_home2y.png) -732px -80px no-repeat;}
#gn9 a:hover { top: 52px; width: 126px; left: 629px; background: url(../img/common/globalNavi_home2y.png) -874px -80px no-repeat;}



.solution #gn1 { width: 194px; left: 0; background: url(../img/common/globalNavi_home2y.png) -245px -28px no-repeat;}
.dreamteam #gn2 { width: 224px; left: 194px; background: url(../img/common/globalNavi_home2y.png) -439px -28px no-repeat;}
.themepark #gn3 { width: 257px; left: 418px; background: url(../img/common/globalNavi_home2y.png) -663px -28px no-repeat;}
.company #gn4 { width: 80px; left: 675px; background: url(../img/common/globalNavi_home2y.png) -920px -28px no-repeat;}
.engineering #gn5 { top: 52px; width: 280px; left: 0; background: url(../img/common/globalNavi_home2y.png) -245px -80px no-repeat;}
.case #gn6 { top: 52px; width: 130px; left: 280px; background: url(../img/common/globalNavi_home2y.png) -525px -80px no-repeat;}
.qa #gn7 { top: 52px; width: 77px; left: 410px; background: url(../img/common/globalNavi_home2y.png) -655px -80px no-repeat;}
#deal #gn8 { top: 52px; width: 142px; left: 487px; background: url(../img/common/globalNavi_home2y.png) -732px -80px no-repeat;}
.company#deal #gn4 { width: 80px; left: 675px; background: url(../img/common/globalNavi_home2.png) -920px -28px no-repeat;}
.inquiry #gn9 { top: 52px; width: 126px; left: 629px; background: url(../img/common/globalNavi_home2y.png) -874px -80px no-repeat;}


/* BREADCRUMBS
------------------------------ */
.breadcrumbs {
	height: 56px;
	ul {
		padding-top: 14px;
		margin-left: 2px;
		li {
			font-size: 12px;
			float: left;
			display: inline;
			zoom: 1;
			line-height: 1.0;
			// padding-bottom: 5px;
			margin-right: 20px;
			&.liEnd {
				margin-right: 0;
			}
			span {
				vertical-align: super;
				padding-left: 16px;
				background: url(../img/common/bullet_raquo_bk.gif) 0 2px no-repeat;
			}
			a {
				text-decoration: underline;
				padding-left: 16px;
				background: url(../img/common/bullet_raquo_bl.gif) 0 2px no-repeat;
			}
		}
	}
}




/* FOOTER INQUIRY MODULE
------------------------------ */
.fi-wrapper {
	width: 100%;
	height: 240px;
	background: url(../img/common/fiLine_b2000.gif) center 23px no-repeat;
	.footerInquiry {
		width: 1000px;
		margin: 0 auto;
		height: 240px;
		position: relative;
		.fiTitle {
			text-align: center;
		}
		.footer-id {
			position: absolute;
			top: 58px;
			left: 0;
		}
		.fiTel {
			position: absolute;
			top: 75px;
			left: 499px;
		}
		.fiForm {
			position: absolute;
			top: 75px;
			left: 760px;
		}
		.fiContact {
			position: absolute;
			top: 176px;
			right: 0;
			font-size: 15px;
			span {
				font-weight: bold;
			}
		}
	}
}


/* FOOTER MODULE
------------------------------ */
.footer-wrapper {
	width: 100%;
	background: url(../img/common/bg_footer_line3.jpg) center top no-repeat;
	position: relative;
	.footer {
		width: 1000px;
		margin: 0 auto;
		min-height: 635px;
		height: auto !important;
		height: 635px;
		background-color: transparent;
		position: relative;
		color: #fff;
		font-size: 13px;
		.footerNavi {
			position: absolute;
			top: 40px;
			left: 10px;
			.fnColumn {
				float: left;
				display: inline;
				zoom: 1;
			&.fn1 {
				width: 300px;
			}
			&.fn2 {
				width: 258px;
			}
			&.fn3 {
				width: 258px;
			}
			&.fn4 {
				width: 174px;
			}
			}
			dl {
				dt {
					font-size: 13px;
					font-weight: bold;
					line-height: 1.0;
					margin-bottom: 15px;
				&.middleCat {
					font-weight: normal;
				}
				&.noChild {
					margin-bottom: 1.6em;
				}
				}
			}
			ul {
				li {
					font-size: 12px;
					line-height: 1.3;
					margin-bottom: 15px;
					margin-right: 38px;
				}
			}
		}
		a {
			color: #fff;
		&:hover{
			text-decoration: underline;
			}
		}
	}
	.copy-wrapper {
		background-color: transparent;
		width: 1000px;
		position: absolute;
		left: 50%;
		margin-left: -500px;
		bottom: 0;
		text-align: center;
		height: 68px;
		.copywright {
			color: #fff;
			letter-spacing: 0.15em;
			span {
				padding: 2px 12px;
				// background-color: #000;
				// opacity: 0.6;
			}
		}
		.pagetop {
			position: absolute;
			right: 0;
			bottom: 62px;
		}
	}
}

/* PAGE NAVI COMMON SETTINGS
------------------------------ */
ul.pageNavi {
	position: relative;
	width: 1000px;
	height: 62px;
	top: 0;
	left: 0;
	li {
		float: left;
		display: inline;
		zoom: 1;
		position: absolute;
		height: 62px;
		line-height: 62px;
		width: 200px;
		a {
			display: block;
			text-indent: -9999px;
			cursor: pointer;
		}
	}
}

.pn1 {left: 0; }
.pn2 {left: 200px; }
.pn3 {left: 400px; }
.pn4 {left: 600px; }
.pn5 {left: 800px; }

.pNavi_top {
	margin-bottom: 82px;
}

.pNavi_bottom {
	.pnTitle {
		margin-bottom: 14px;
	}
}

/* CONTENTS COMMON SETTINGS
   FOR SINGLE COLUMN PAGE
------------------------------ */
body.col1 {
	.contents {
		.section {
			padding-bottom: 140px;
			p {
				font-size: 16px;
			}
			.unit {
				// min-height: 400px;
				// height: auto !important;
				// height: 400px;
			}
		}
	}
}